import { useState, useEffect } from '@/common'
import pca from '../assets/json/pca.json'
import { Cascader } from 'antd';

function Main(props: any) {
    const [value, setValue] = useState();

    const setPCA = (val: any) => {
        setValue(val)
        props.onChange(val)
    }
    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    return (
        <Cascader options={pca} value={value} fieldNames={{ label: "text", value: props.valueKey || "text" }} placeholder="请选择省市区" onChange={setPCA} />
    )
}

export default Main