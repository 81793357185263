import { useState, useEffect } from '@/common'
import { Space, Switch, Input, Select, InputNumber } from 'antd';
import './SwitchInput.scss';

function Main(props: any) {
    const [datas, setDatas] = useState<any>({
        valid: false
    });
    const [default_status, setDefaultStatus] = useState(true)

    const onChange = (name: string, data: any) => {
        let one = { ...datas }
        one[name] = data
        setDatas(one)
        props.onChange(one)
        if (props.onChanged && typeof (props.onChanged) == "function") props.onChanged(one)
    }

    useEffect(() => {
        // 执行一次
        if (default_status && props.value) {
            setDatas(props.value)
            setDefaultStatus(false)
        }
    }, [props.value])

    return (
        <Space className='SwitchInput'>
            <Switch checked={datas.valid} onChange={(e) => {
                onChange('valid', e)
            }} />
            {
                datas.valid && props.inputs.map((e: any, k: number) => {
                    if (e.input === 'Input') {
                        return <div className='item' key={k}>
                            <div>{e.label}：</div>
                            <Input value={datas[e.name]} placeholder={`请输入${e.label}`} {...e.property} onChange={(ele: any) => {
                                onChange(e.name, ele.target.value)
                            }} />
                        </div>
                    }
                    if (e.input === 'InputNumber') {
                        return <div className='item' key={k}>
                            <div>{e.label}：</div>
                            <InputNumber value={datas[e.name]} placeholder={`请输入${e.label}`} {...e.property} onChange={(ele: any) => {
                                onChange(e.name, ele)
                            }} />
                        </div>
                    }
                    if (e.input === 'Select') {
                        return <div className='item' key={k}>
                            <div>{e.label}：</div>
                            <Select
                                value={datas[e.name]}
                                placeholder={`请选择${e.label}`}
                                onChange={(ele: any) => {
                                    onChange(e.name, ele)
                                }} {...e.property}>
                            </Select>
                        </div>
                    }
                })
            }
            {/* <Form
                form={form}
                layout='inline'
                onValuesChange={onValuesChange}
            >
                <Form.Item
                    name="valid"
                >
                    <Switch onChange={(e) => {
                        setValid(e)
                    }} />
                </Form.Item>
                {valid && props.children}
            </Form> */}
        </Space>
    )
}

export default Main